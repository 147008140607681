import React, { useState, useEffect, useRef } from 'react';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import { Container, Grid, Button, Box, Paper, TextField, Typography, Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import { formatDate } from '../shared/formatDate';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import QrCodeScanner from '@mui/icons-material/QrCodeScanner';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Tooltip from '@mui/material/Tooltip';

import { apiService } from '../services/apiService';
import { authService } from '../services/authService';
import Toast from '../shared/toast';
import TestKitDialog from './DialogTestActivate.js'; 

const initialState = {
    testId: 0,
    userId: 0,
    firstName: "",
    lastName: "",
    lastTestId: 0,
    lastTestKit: "",
    activated: "",
    labReceived: "",
    finished: "",
    testunfinished: false,
    expectedDropOff: dayjs().add(1, 'day'),
};

const TestActivate = () => {
    const [clienterror, setClienterror] = useState('');
    const [lstClients, setLstClients] = useState([]);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false); 
    const [iserror, setIserror] = useState(false);
    const [fields, setFields] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [openToast, setOpenToast] = useState(false);
    const [msgToast, setmsgToast] = useState('Kit Activated.');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('firstName');
    const [searchText, setSearchText] = useState('');
    const testKitIdRef = useRef(null);
    const [filteredRows, setFilteredRows] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {
                const clientsxresults = await apiService.getResultxUsers();
                if (Array.isArray(clientsxresults.data)) {
                    const clientlst = clientsxresults.data.map(users => ({
                        id: users.user.id , 
                        lastTestId: users.lastTestId || 0,
                        userId: users.user.id || 0,
                        firstName: users.user.firstName || '',
                        lastName: users.user.lastName || '',
                        activated: users.activated || '',
                        labReceived: users.labReceived || '',
                        lastTestKit: users.lastTestKit || '',
                        testUnfinished: users.unfinished || '',
                        expectedDropOff: formatDate(users.expectedDropOff, false)  || '',
                    }));

                    setLstClients(clientlst);
                    setFilteredRows(clientlst);
                }
            } catch (error) {
                console.error('Error fetching clients.', error);
            }
        }

        fetchData();
    }, [refresh]);

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                if (testKitIdRef.current) {
                    testKitIdRef.current.focus();
                }
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [open]);

    // Função SetToactivateKit
    const SetToactivateKit = (row) => {
        setFields({
            userId: row.userId,
            id: row.lastTestId,
            testKitId: row.lastTestKit,
            firstName: row.firstName,
            lastName: row.lastName,
            expectedDropOff: row.expectedDropOff ? dayjs(row.expectedDropOff) : null, 
        });
        setOpen(true);
    };

    const handleChange = (e, field) => {
        setFields(prev => ({
            ...prev,
            [field]: e.target.value,
        }));
        setClienterror('');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleActivate = async () => {
        if (fields.testKitId && fields.expectedDropOff) {
            try {
                let newTestResult;
                if (fields.id > 0) {
                    const testResult = await apiService.getTestResultById(fields.id);
                    testResult.data.expectedDropOff = fields.expectedDropOff;
                    testResult.data.testKitId = fields.testKitId;
                    newTestResult = await apiService.updateTestResult(testResult.data);
                    setmsgToast('Kit updated.');
                    setOpenToast(true);
                    setIserror(false);
                } else {
                    newTestResult = await apiService.activatetest(fields);
                    setmsgToast('Kit Activated.');
                    setOpenToast(true);
                    setIserror(false);
                }
                //await apiService.createQuestionnaireUser(newTestResult.data.id);
            } catch (error) {
                setmsgToast(error.response.data);
                setIserror(true);
                setOpenToast(true);
            }
            if (!iserror) {
                setRefresh(!refresh);
                setOpen(false);
            }
        } else {
            setIserror(true);
            setmsgToast('Provide kit number and expected dropoff date');
            setOpenToast(true);
        }
    };

    const handleDateChange = (date) => {
        setFields((prev) => ({ ...prev, expectedDropOff: date }));
    };

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchText(query);
        const filteredData = lstClients.filter(clientlst =>
            clientlst.firstName.toLowerCase().includes(query) ||
            clientlst.lastName.toLowerCase().includes(query)
        );
        setFilteredRows(filteredData);
      };

      const columns = [
        { field: 'firstName', headerName: 'First Name', flex: 1},
        { field: 'lastName', headerName: 'Last Name', flex: 1},
        { field: 'lastTestKit', headerName: 'Active Test Kit', flex: 1 },
        { field: 'expectedDropOff', headerName: 'Expected DropOff', flex: 1}, 
        {
            field: 'labReceived', headerName: 'Lab Received', flex: 1,  renderCell: (params) => (
                params.value !== "" ? (
                    <Tooltip title={`Laboratory received on: ${params.value}`}>
                        <CheckIcon style={{ color: 'green' }} />
                    </Tooltip>
                ) : (
                    <CloseIcon style={{ color: 'gray' }} />
                )
            )
        },
        {
            field: 'activate', headerName: 'Activate Test Kit', renderCell: (params) => (
                <Tooltip title="Click to activate new kit">
                    <Button 
                        variant="outlined"
                        size="small"
                        onClick={() => SetToactivateKit(params.row)}
                    >
                            <QrCodeScanner/>
                    </Button>
                </Tooltip>
            )
        }
    ];

    return (
        <>
            <div className="bgColor">
                <Box sx={{ display: 'flex' }}>
                    <SideBar />
                    <Box
                        component="main"
                        sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', p: 3 }}
                    >
                        <NavBar />
                        <Container maxWidth="xl" sx={{ mt: 2, mb: 2 }}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant="h4" sx={{ mb: 2 }}>
                                    Activate a Test Kit
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            placeholder="Search"
                                            value={searchText}
                                            onChange={handleSearchChange}
                                            InputProps={{
                                                startAdornment: <SearchIcon />,
                                                endAdornment: (
                                                    <Button onClick={() => {
                                                        setSearchText('');
                                                        setFilteredRows(lstClients);
                                                    }}>
                                                        <ClearIcon />
                                                    </Button>
                                                ),
                                            }}
                                            sx={{
                                                '.MuiOutlinedInput-root': {
                                                    borderRadius: '5px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                },
                                                '.MuiInputAdornment-root': {
                                                    margin: '0 4px',
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Box sx={{ mt: 2 }}>
                                    <DataGrid
                                        fullWidth
                                        rows={filteredRows}
                                        columns={columns.map((column) => ({
                                            ...column,
                                            flex: 1, // Allows each column to auto-size based on content
                                            p: 2
                      
                                          }))}
                                        onPageChange={(newPage) => setPage(newPage)}
                                        initialState={{
                                            pagination: {
                                              paginationModel: {
                                                pageSize: 5,
                                              },
                                            },
                                          }}
                                          pageSizeOptions={[5]}
                                          disableSelectionOnClick // Disables row selection on click
                                          checkboxSelection={false} // Removes the checkbox column
                                          components={{
                                            Pagination: () => (
                                              <Pagination
                                                count={Math.ceil(filteredRows.length / 5)}
                                                shape="rounded"
                                                hidePrevButton={filteredRows.length <= 5} // Hide previous button if only one page
                                                hideNextButton={filteredRows.length <= 5} // Hide next button if only one page
                                              />
                                            ),
                                          }}
                                        autoHeight
                                        sx={{
                                            '& .MuiTablePagination-selectLabel': {
                                              display: 'none',      // Hide the "Rows per page" text
                                            },
                                            '& .MuiTablePagination-displayedRows': {
                                              display: 'none',      // Hide the "6–10 of 22" text
                                            },
                                            '& .MuiInputBase-root': {
                                              display: 'none',      // Hide the rows per page dropdown
                                            },
                                            backgroundColor: "#ffffff",
                                            "& .MuiDataGrid-columnHeaders": {
                                              fontWeight: "bold",  // Set the font weight of headers to bold
                                            },
                                            "& .MuiDataGrid-columnHeaderTitle": {
                                              fontWeight: "bold",  // Ensure text in headers is bold
                                            },
                                          }}
                                    />
                                </Box>
                            </Paper>
                        </Container>
                    </Box>
                </Box>
            </div>

            <TestKitDialog
                open={open}
                handleClose={handleClose}
                handleActivate={handleActivate}
                fields={fields}
                handleChange={handleChange}
                handleDateChange={handleDateChange}
                errors={errors}
            />

            <Toast open={openToast} handleClose={() => setOpenToast(false)} message={msgToast} error={iserror} />
        </>
    );
};

export default TestActivate;
