import './PartnerDetail.css';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Box, mobileStepperClasses } from "@mui/material";
import { apiService } from '../services/apiService';
import LogoImage from "../assets/logo_partner.png";

const PartnerDetail = (content) => {

    const navigate = useNavigate();
    const [idpartner, setIdpartner] = useState('');
    const [address, setAddress] = useState({
        id: '',
        streetAddress: '',
        extendedAddress: '',
        provinceOrState: '',
        city: '',
        country: '',
        postalCode: '',
        logo: ''
    });

    const [fields, setFields] = useState({
        id: '',
        name: '',
        partnerType: '',
        streetAddress: '',
        extendedAddress: '',
        provinceOrState: '',
        city: '',
        country: '',
        postalCode: '',
        logo: ''
    });
    const [isMobile, setIsMobile] = useState(false);
    const [Mobile, setMobile] = useState(600);

    useEffect(() => {
 
      setIsMobile(window.innerWidth < 600);
      setMobile(window.innerWidth < 600 ? 300 : 600);
      setIdpartner(String(content.data));
      async function fetchData() {
            try {
                if (idpartner) {
                    const partner = await apiService.partnerbyid(idpartner);
                    const partnerTypeData = { param: String(partner.data.partnerTypeId) };

                    const partnertype = await apiService.partnertypebyid(partnerTypeData);
                    const addressId = partner.data.addressId;

                    if (addressId) {
                        const partneradress = await apiService.addressbyid({ param: String(addressId) })
                        setAddress(partneradress.data);
                        setFields({
                            id: partner.data.id,
                            name: partner.data.name,
                            partnerType: partnertype.data.type,
                            streetAddress: partneradress.data.streetAddress,
                            extendedAddress: partneradress.data.extendedAddress,
                            provinceOrState: String(partneradress.data.provinceOrState.name),
                            city: String(partneradress.data.city.name),
                            country: partneradress.data.country,
                            postalCode: partneradress.data.postalCode,
                            logo: partner.data.logo,
                            site: partner.data.site
                        });
                    }
                }

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [idpartner]);

    return (
      <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
        }}
      >
        <Box 
          sx={{ 
            display: "flex", 
            flexDirection: isMobile ? 'column':'row', 
            maxWidth: isMobile ? 300 : 600, 
            mx: 0, 
            p: 1, 
            alignItems: "center" 
          }}
        >
          <Box sx={{ flex: "0 0 auto", mb: isMobile ? 2 : 0, mr: isMobile ? 10 : 5 }}>
            <img
              src={fields.logo ? `data:image/png;base64,${fields.logo}` : LogoImage}
              alt="Base64 Image"
              className="logo-image-lg"
            />
          </Box>
          <Box sx={{ flex: "1 1 auto", padding: 0, width: Mobile }}>
            <p style={{ color: 'gray', margin: 0, fontSize: isMobile ? '16px' : '22px'}}>
              <b>{fields.name}</b>
            </p>
            <span style={{ color: '#000000', margin: '0px', position: 'relative', width: '100%', borderBottom: '1px solid #142F3C' }}></span>
            <p style={{ color: 'gray', margin: 0, fontSize: isMobile ? '16px' : '22px'}}>{fields.partnerType}</p>
            <p style={{ color: 'gray', margin: 0, fontSize: isMobile ? '14px' : '18px'}}>
              {fields.streetAddress}<br/>
              {fields.extendedAddress && <span>{fields.extendedAddress}<br/></span>}
              {fields.city}{", "}{fields.provinceOrState}<br/>
              {fields.postalCode}{", "}{fields.country}<br/>
              <a href={`http://${fields.site}`} target="_blank" rel="noopener noreferrer">{fields.site}</a>
             
            </p>
          </Box>
        </Box>
      </Box>
    </div>
    );
  };
  

export default PartnerDetail;