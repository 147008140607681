import Toast from "../shared/toast";
import BannerBackground from "../assets/vector-8.png";
import BannerImage from "../assets/Login_img.png";
import { TextField, Button, Box, Grid, Stack, CssBaseline, Container } from "@mui/material";
import FooterBar from "../shared/footer";
import { useNavigate, NavLink } from 'react-router-dom';
import { useEffect } from "react";
import { authService } from "../services/authService";
import '../global.css';
import './Home.css';
import NavBar from '../shared/navBar';
import { useState } from "react";
import SideBar from '../shared/sideBar';

const SoonPage = () => {
    const navigate = useNavigate();
    const [logged, setLogged] = useState(false);

    useEffect(() => {
        fetchData();

        async function fetchData() {
            const isLoggedIn = await authService.isLoggedIn();
            setLogged(isLoggedIn)
        };
        //if (onHome) {
        //    onHome();
        //}
        //navigate('/dashboard');

    }, [logged]);



    return (

        <>
          <Box sx={{ display: 'flex' }}>
            <SideBar />
            <CssBaseline />
            <Box
              component="main" sx={{
                flexGrow: 1,
                overflow: 'visible',
                height: '100vh',
                p: 3,
              }}>
                <NavBar />
              <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                <Grid container spacing={2}>

                <Grid container spacing={2}>
                  <Grid item xs={6}>


                <div className="welcome">
                        <h1 className="info">
                        Under construction        </h1>

                        <p className="info">
                            <b>We are working on it now...</b> <br /> <br />
                            </p>
                    </div>
                    </Grid>
                    </Grid>
                </Grid>
                <Box height={70} />
              </Container>
            </Box>
          </Box>
        </>
    );
}

export default SoonPage;
