import { Button, Box, DialogTitle, DialogContentText, Typography, DialogContent, Stack, DialogActions, Card, CardContent, Dialog, TextField, MenuItem, Paper, Select, InputLabel, FormControl, Grid } from "@mui/material";
import { useState, useEffect } from 'react';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import { DataGrid } from '@mui/x-data-grid';
import { LineChart } from '@mui/x-charts/LineChart';
import { FaUserInjured } from "react-icons/fa";
import { FaBriefcaseMedical } from "react-icons/fa6";
import { apiService } from '../services/apiService';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from "react-router-dom";

const AdminDash = () => {

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,
      editable: true,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 110,
      editable: true,
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
    },
  ];

  const rows = [
    { id: 1, lastName: '', firstName: '', age: 0 },
  ];

  const [partnererror, setPartnererror] = useState('');
  const navigate = useNavigate();

  const defaultTheme = createTheme();

  const [lstPartners, setlstPartnerAll] = useState([]);
  const [open, setOpen] = useState(false);
  const [partnerId, setPartnerId] = useState(0);

  const resetForm = () => {
    setFields(initialState);
  };

  const initialState = {
    Name: "",
    email: "",
    mobile: "",
    PartnerType: {
      Type: ""
    },
    partnerAddress: {
      streetAddress: "",
      extendedAddress: "",
      postalCode: "",
      country: "",
      provinceOrState: {
        name: ""
      },
      city: {
        name: ""
      }
    }
  };

  const [fields, setFields] = useState(initialState);

  const handlePartnerChange = (e, field) => {
    setFields(prev => ({
      ...prev,
      [field]: { Type: e.target.value },
    }));
  };


  const [selectedPartner, setSelectedPartner] = useState('');
  const [totaltesters, setTotaltesters] = useState(0);
  const [totaltests, setTotaltests] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const partners = await apiService.partnerall();
        if (Array.isArray(partners.data)) {
          const names = partners.data.map(item => ({ id: item.id, name: item.name }));
          setlstPartnerAll(names);
        };

      } catch (error) {
        console.error('Error fetching partner types.', error);
        // Handle error
      }
    }

    fetchData();
  }, []);

  const addNew = () => {
    navigate('/partnerinfo/0');
  }

  const handleChange = (e, field) => {
    setFields(prev => ({
      ...prev,
      [field]: e.target.value,
    }));

    setPartnererror('');
  };

  const edit = () => {
    if (partnerId === 0) {
      setPartnererror('* Select partner for edit');
    }
    else {
      navigate(`/partnerinfo/${partnerId}`);
    }
  }

  return (
    <>
      <div className="bgColor">
        <Box sx={{ display: 'flex' }}>
          <SideBar />
          <CssBaseline />
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', p: 3 }}>
           <NavBar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

              <Box height={70} />

              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Stack spacing={4} direction="row" justifyContent="center">
                    <Card sx={{ p: 2, minWidth: 250 }}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 3 }}>
                        <Grid item xs={6} container
                          direction="row" justifyContent="flex-start"
                          alignItems="center">
                          <FaUserInjured style={{ fontSize: "3rem", paddingLeft: "0.5rem" }} />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h5" sx={{ fontFamily: "Roboto", fontWeight: "bold" }}>Total Testers</Typography>
                          <CardContent>
                            <Typography variant="h5">
                              {totaltesters}
                            </Typography>
                          </CardContent>
                        </Grid>
                      </Grid>
                    </Card>
                    <Card sx={{ p: 2, minWidth: 250 }}>
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={6} container
                          direction="row" justifyContent="flex-start"
                          alignItems="center">
                          <FaBriefcaseMedical style={{ fontSize: "3rem", paddingLeft: "0.5rem" }} />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h5" sx={{ fontFamily: "Roboto", fontWeight: "bold" }}>Total Tests</Typography>
                          <CardContent>
                            <Typography variant="h5">
                              {totaltests}
                            </Typography>
                          </CardContent>
                        </Grid>
                      </Grid>
                    </Card>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={4} direction="row">
                    <Card sx={{ width: '100%', minWidth: 500 }}>
                      <Typography variant="h5" sx={{ p: 2 }}>Tests Completed </Typography>

                      <LineChart
                        xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                        series={[
                          {
                            data: [2, 5.5, 2, 8.5, 1.5, 5],
                            area: true,
                            color: 'red',
                            fillOpacity: 0.1
                          },
                          {
                            data: [4, 5, 3, 6.5, 0.5, 3.5],
                            area: true,
                            color: 'blue',
                            fillOpacity: 0.1,
                          },
                        ]
                        }
                        height={350}
                        grid={{ vertical: true, horizontal: true }}
                      />

                    </Card>
                    <Card sx={{ width: '100%', minWidth: 500 }}>
                      <Typography variant="h5" sx={{ p: 2 }}>Recent Test Result </Typography>

                      <CardContent>
                        <DataGrid
                          rows={rows}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 4,
                              },
                            },
                          }}
                          pageSizeOptions={[4]}
                          checkboxSelection
                          disableRowSelectionOnClick
                        />
                      </CardContent>
                    </Card>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <h2 sx={{ p: 1 }}>Recent Testers</h2>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      pageSizeOptions={[5]}
                      checkboxSelection
                      disableRowSelectionOnClick
                      sx={{ backgroundColor: "#ffffff" }}
                    />
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </div>
    </>
  )
};

export default AdminDash;