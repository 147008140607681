// HalfDoughnutChart.js
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const HalfDoughnutChart = ({ percentage, label }) => {
  const data = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: ['#36A2EB', '#EAEAEA'], // Preenchido e vazio
        hoverBackgroundColor: ['#36A2EB', '#EAEAEA'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    rotation: -90, // Inicia do topo
    circumference: 180, // Semicírculo
    cutout: '70%', // Espessura do anel
    plugins: {
      tooltip: { enabled: true },
      legend: { display: false },
    },
  };

  return (
    <div style={{ textAlign: 'center', width: 150, height: 100 }}>
      <Doughnut data={data} options={options} />
      <h4>{label}</h4>
      <p>{percentage}%</p>
    </div>
  );
};

export default HalfDoughnutChart;
