import Toast from "../shared/toast";
import BannerBackground from "../assets/vector-8.png";
import BannerImage from "../assets/Login_img.png";
import { TextField, Button, Box, Grid, Stack } from "@mui/material";
import FooterBar from "../shared/footer";
import { useNavigate, NavLink } from 'react-router-dom';
import NavBar from '../shared/navBar';
import '../global.css';
import './Home.css';

const LabDash = () => {

    const navigate = useNavigate();

    return (

        <div>
            <div className="home-container">
                <NavBar/>
                <div>
                    <div className="home-text-section">
                        <h1 className="primary-heading">
                        LabDash</h1>
                        <br/>
                        <button className="primary-button" onClick={() => navigate("/")}>Go to HomePage</button>
                     </div>
                </div>
            </div>

        </div>
    );
}

export default LabDash;
