import { Button, Box, DialogTitle, DialogContentText, DialogContent, Stack, CardContent, DialogActions, Card, Typography, Dialog, TextField, MenuItem, Paper, Select, InputLabel, FormControl, Grid } from "@mui/material";
import { useState, useEffect } from 'react';
import { apiService } from '../services/apiService';
import { DataGrid } from '@mui/x-data-grid';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import { LineChart } from '@mui/x-charts/LineChart';
import { authService } from "../services/authService";

function ClientDash() {

  const [logged, setLogged] = useState(false);

  useEffect(() => {
      fetchData();

      async function fetchData() {
          const isLoggedIn = await authService.isLoggedIn();
          setLogged(isLoggedIn)
      };
      //if (onHome) {
      //    onHome();
      //}
      //navigate('/dashboard');

  }, [logged]);

  return (
    <>
      <div className="bgColor">
        <Box sx={{ display: 'flex' }}>
          <SideBar />
          <CssBaseline />
          <Box component="main" sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            p: 3,
          }}>
            <NavBar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Box height={50} />

              <Grid item xs={12}>
              <div className="welcome">
                        <h1 className="info">
                            Welcome        </h1>

                        {logged && <p className="info">
                            <b>You are logged into the system...</b> <br /> <br />
                            </p>}

                        {!logged && <div>
                            <p className="info">
                                <b>You are not logged into the system.</b> <br />
                                <br />
                            </p></div>}
                    </div>
              </Grid>


              <Box height={50} />

              <Box height={20} />

            </Container>
          </Box>
        </Box>
      </div>
    </>
  )
};

export default ClientDash;

