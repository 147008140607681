import './App.css';
import { authService } from './services/authService';
import { useState, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { RoleBasedRoute } from './routes/RoleBasedRoute';
import HomePage from './pages/Home';
import NotFound from './pages/NotFound';
import LoginPage from './pages/LoginPage';
import SoonPage from './pages/Soon';
import ResetPassword from './pages/ResetPassword';
import ContactPage from './pages/Contact';
import BePartner from './pages/BePartner';
import UserInfo from './pages/UserInfo';
import UserNew from './pages/UserNew';
import PartnerFind from './pages/PartnerFind';
import PartnerInfo from './pages/PartnerInfo';
import PartnerDetail from './pages/PartnerDetail';
import ManagePartner from './pages/ManagePartner';
import DialogPartner from './pages/DialogPartner';
import PartnerScanKit from './pages/PartnerScanKit';
import Questionnaire from './pages/Questionnaire';
import QuestionnaireQuestions from './pages/QuestionnaireQuestions';
import TestActivate from './pages/TestActivate';
import TestsProgress from './pages/TestsProgress';
//import ManagePatient from './pages/Patient/ManagePatient';
import LabReceipt from './pages/LabReceipt';
import ManageUser from './pages/ManageUser';
import ManageClient from './pages/ManageClient';
import ManageTestResults from './pages/ManageTestResults';
import TestScore from './pages/TestScore';
import PartnerDash from './pages/PartnerDash';
import PartnerView from './pages/PartnerView';
import AdminDash from './pages/AdminDash';
import LabDash from './pages/LabDash';
import ClientDash from './pages/ClientDash';
import InactivityLogout from './services/killSession'; 
const response = await fetch('routeconfig.json');
const routeconfig = await response.json();

function App() {
  const [userRole, setUserRole] = useState('');
  const [routeConfig, setRouteConfig] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRouteConfig = async () => {
      const response = await fetch('/routeconfig.json');
      const config = await response.json();
      setRouteConfig(config);
    };

    fetchRouteConfig();
  }, []);

  if (!routeConfig) {
    return <div>Loading...</div>; // Show a loading indicator while fetching the route config
  }

  return (<>
      <InactivityLogout /> 
    <Routes>
      <Route path='/' element={<RoleBasedRoute allowedRoles={routeconfig.home}><HomePage /></RoleBasedRoute>} />
      <Route path='/home' element={<RoleBasedRoute allowedRoles={routeconfig.home}><HomePage /></RoleBasedRoute>} />
      <Route path='/notfound' element={<RoleBasedRoute allowedRoles={routeconfig.home}><NotFound /></RoleBasedRoute>} />
      <Route path='/contact' element={<RoleBasedRoute allowedRoles={routeconfig.contact}><ContactPage /></RoleBasedRoute>} />
      <Route path='/bepartner' element={<RoleBasedRoute allowedRoles={routeconfig.partnerregister}><BePartner /></RoleBasedRoute>} />
      <Route path='/PartnerFind' element={<RoleBasedRoute allowedRoles={routeconfig.partnerfind}><PartnerFind /></RoleBasedRoute>} />
      
      <Route path='/PartnerView' element={<RoleBasedRoute allowedRoles={routeconfig.admindash}><PartnerView /></RoleBasedRoute>} />
      <Route path='/partnerDetail' element={<RoleBasedRoute allowedRoles={routeconfig.partnerdetail}><PartnerDetail /></RoleBasedRoute>} />
      <Route path='/partnerinfo' element={<RoleBasedRoute allowedRoles={routeconfig.partnerinfo}><PartnerInfo /></RoleBasedRoute>} />
      <Route path='/dialogpartner' element={<RoleBasedRoute allowedRoles={routeconfig.partnerinfo}><DialogPartner /></RoleBasedRoute>} />
      <Route path='/managepartner' element={<RoleBasedRoute allowedRoles={routeconfig.managepartner}><ManagePartner/></RoleBasedRoute>} />
      <Route path='/login' element={<RoleBasedRoute allowedRoles={routeconfig.login}><LoginPage /></RoleBasedRoute>} />
      <Route path='/soonpage' element={<SoonPage />} />

      <Route path='/admindash' element={<RoleBasedRoute allowedRoles={routeconfig.admindash}><AdminDash /></RoleBasedRoute>} />
      <Route path='/partnerdash' element={<RoleBasedRoute allowedRoles={routeconfig.partnerdash}><PartnerDash /></RoleBasedRoute>} />
      <Route path='/labdash' element={<RoleBasedRoute allowedRoles={routeconfig.labdash}><LabDash /></RoleBasedRoute>} />
      <Route path='/clientdash' element={<RoleBasedRoute allowedRoles={routeconfig.clientdash}><ClientDash /></RoleBasedRoute>} />
      
      <Route path='/labReceipt' element={<RoleBasedRoute allowedRoles={routeconfig.labReceipt}><LabReceipt /></RoleBasedRoute>} />
      
      <Route path='/questionnaire' element={<RoleBasedRoute allowedRoles={routeconfig.questionnaire}><Questionnaire /></RoleBasedRoute>} />
      <Route path='/questionnaireQuestions/:questionnaireId/:questionnaireUserId' element={<RoleBasedRoute allowedRoles={routeconfig.questionnaire}><QuestionnaireQuestions /></RoleBasedRoute>} />
      <Route path='/manageuser' element={<RoleBasedRoute allowedRoles={routeconfig.manageuser}><ManageUser /></RoleBasedRoute>} />
      <Route path='/manageclient' element={<RoleBasedRoute allowedRoles={routeconfig.manageclient}><ManageClient /></RoleBasedRoute>} />
      <Route path='/managetestresults' element={<RoleBasedRoute allowedRoles={routeconfig.managetestresults}><ManageTestResults /></RoleBasedRoute>} />
      <Route path='/testscore' element={<RoleBasedRoute allowedRoles={routeconfig.testscore}><TestScore /></RoleBasedRoute>} />
      <Route path='/resetpassword/:usertoken' element={<RoleBasedRoute allowedRoles={routeconfig.resetpassword}><ResetPassword /></RoleBasedRoute>} />
      <Route path='/userinfo' element={<RoleBasedRoute allowedRoles={routeconfig.userinfo}><UserInfo /></RoleBasedRoute>} />
      <Route path='/usernew' element={<RoleBasedRoute allowedRoles={routeconfig.usernew}><UserNew /></RoleBasedRoute>} />
      <Route path='/testActivate' element={<RoleBasedRoute allowedRoles={routeconfig.testactivate}><TestActivate/></RoleBasedRoute>} />
      <Route path='/testsProgress' element={<RoleBasedRoute allowedRoles={routeconfig.testsprogress}><TestsProgress/></RoleBasedRoute>} />

      <Route path='/partnerscankit' element={<RoleBasedRoute allowedRoles={routeconfig.partnerscankit}><PartnerScanKit/></RoleBasedRoute>} />
      <Route path='/*' element={<NotFound />} />
    </Routes>
    </>
  );
}

export default App;
