import React, { useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Grid } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const DialogTestActivate = ({ open, handleClose, handleActivate, fields, handleChange, handleDateChange, errors }) => {
    const testKitIdRef = useRef(null);

    useEffect(() => {
        if (open) {
            const timer = setTimeout(() => {
                if (testKitIdRef.current) {
                    testKitIdRef.current.focus();
                }
            }, 100);
            return () => clearTimeout(timer);
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{fields.id > 0 ? "Update Test Kit" : "Activate Test Kit"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Client: {`${fields.firstName} ${fields.lastName}`}
                </DialogContentText>
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Test Kit Identification"
                            value={fields.testKitId}
                            onChange={(e) => handleChange(e, 'testKitId')}
                            fullWidth
                            error={!!errors.testKitId}
                            helperText={errors.testKitId}
                            inputRef={testKitIdRef}
                            sx={{ mb: 2 }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleActivate();
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ mb: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Expected Drop Off"
                            value={fields.expectedDropOff}
                            onChange={handleDateChange}
                            minDate={dayjs()}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleActivate} sx={{ width: "150px", backgroundColor: "rgba(197, 220, 255, 1)", color: "#000000", fontWeight: "bold", fontFamily: "Roboto", borderColor: "rgba(197, 220, 255, 1)" }}>
                    {fields.id > 0 ? "Update" : "Activate"}
                </Button>
                <Button variant='contained' onClick={handleClose} sx={{ width: "150px", backgroundColor: "rgba(197, 220, 255, 1)", color: "#000000", fontWeight: "bold", fontFamily: "Roboto", borderColor: "rgba(197, 220, 255, 1)" }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DialogTestActivate;
