import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import Container from '@mui/material/Container';
import { TextField, Button, Box, InputLabel, MenuItem, Select, FormControl, FormControlLabel, Checkbox, Grid } from "@mui/material";
import FooterBar from "../shared/footer";
import Toast from "../shared/toast";
import { apiService } from '../services/apiService';
import Autocomplete from '@mui/material/Autocomplete';
import { authService } from '../services/authService';
import { validator } from '../shared/validator';
import urlHomeBase from '../services/urlBase';
const UserInfo = () => {

  const [lstProvOrState, setlstProvOrState] = useState([]);
  const [lstCities, setlstcities] = useState([]);
  const [customValue, setCustomValue] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [msgToast, setmsgToast] = useState('');
  const [returnto, setReturnto] = useState('/');
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [userIdCall, setUserIdCall] = useState(0);
  const [userRole, setUserRole] = useState('');
  const [userData, setUserData] = useState(null); 
  const navigate = useNavigate();
  const [urlBase, setUrlBase] = useState('');
  const [fields, setFields] = useState({
    email: '',
    firstName: '',
    lastName: '',
    id: 0,
    mobile: '',
    ethnicityId: '',
    birthDate: '',
    sexAtBirth: '',
    streetAddress: '',
    extendedAddress: '',
    provinceOrState: '',
    city: '',
    country: 'CA',
    postalCode: '',
    role: 9,
    active: false
  });

  const { userParam } = useParams();
  useEffect(() => {
    async function fetchData() {
      try {
        const storeduser = authService.getUserData();
        setUserRole(storeduser.role);
        const baseUrl = urlHomeBase[storeduser.role];
        setUrlBase(baseUrl);
        //se veio por link de email - o token terá mais que 100 caract
        if (userParam && userParam.length > 100) {
          const userData = { Param: userParam };
          const response = await apiService.validatemailtoken(userData);
          const iduser = await Number(response.data);
          if (iduser) {
            setUserIdCall(iduser);
          }
        } else {
          if (userParam) {
            setUserIdCall(Number(userParam));

          }
          else {


            if (storeduser !== null) {
              setUserIdCall(storeduser.id);
            }
          }
        }
      } catch (error) {
        setmsgToast((error.message || 'Error occurred') + 'Check the entered data.');
        setReturnto(null);
        setError(true);
        setOpenToast(true);
      }
    }

    fetchData();
  }, [userParam]);

  useEffect(() => {
    if (userIdCall !== null) {
      fetchUserDetails(userIdCall);
    }
  }, [userIdCall]);

  const fetchUserDetails = async (id) => {
    try {
      //if (setReturnto !== null) {
      //  navigate(urlOrigin);
      //  return;
      //}

      const provorstate = await apiService.provinceorstateall();
      if (Array.isArray(provorstate.data)) {
        const names = provorstate.data.map(item => item.name);
        setlstProvOrState(names);
      }

      const cities = await apiService.citiesall();
      if (Array.isArray(cities.data)) {
        const names = cities.data.map(item => item.name);
        setlstcities(names);
      }

      if (id > 0) {
        const userData = { Param: String(id) };
        const userResponse = await apiService.userbyid(userData);
        const user = userResponse.data;
        setUserData(user);
        const addressId = user.addressId ? String(user.addressId) : null;
        let address = null;

        if (addressId) {
          const addressResponse = await apiService.addressbyid({ Param: addressId });
          address = addressResponse.data;
        }

        setFields({
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          id: user.id,
          mobile: user.mobile,
          ethnicityId: user.ethnicityId,
          birthDate: user.birthDate,
          sexAtBirth: user.sexAtBirth,
          streetAddress: address ? address.streetAddress : '',
          extendedAddress: address ? address.extendedAddress : '',
          provinceOrState: address ? String(address.provinceOrState.name) : '',
          city: address ? String(address.city.name) : '',
          country: address ? address.country : 'CA',
          postalCode: address ? address.postalCode : '',
          role: user.role ? user.role : 9,
          active: user.active
        });
      }
    } catch (error) {
      setmsgToast((error.message || 'Error occurred') + '<br/>Check the entered data.');
      setReturnto(null);
      setError(true);
      setOpenToast(true);
    }
  };



  const handleChange = (e, field, newValue = null) => {
    setFields(prev => ({
        ...prev,
        [field]: field === 'active' ? e.target.checked : (newValue !== null ? newValue : e.target.value),
    }));
};

const handleInputChange = (e, newValue, field) => {
    setFields(prev => ({
        ...prev,
        [field]: newValue,  // Directly set the new input value
    }));
};


  const handleCloseToast = () => {
    setOpenToast(false);
    if (returnto !== null) {
      navigate(returnto);
    }
  };

  const resetForm = () => {
    setFields({
      email: '',
      firstName: '',
      lastName: '',
      id: '',
      mobile: '',
      ethnicityId: '',
      birthDate: '',
      sexAtBirth: '',
      streetAddress: '',
      extendedAddress: '',
      provinceOrState: '',
      city: '',
      country: 'CA',
      postalCode: '',
      role: 9,
      active: false
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailValidationMsg = validator.validateEmail(fields.email);
    setEmailError(emailValidationMsg);
    if (emailValidationMsg === '') {
      try {
          if (userIdCall > 0 && userData) {
            // Atualize apenas os campos que mudaram
            userData.email = fields.email.toLowerCase();
            userData.firstName = fields.firstName.toUpperCase();
            userData.lastName = fields.lastName.toUpperCase();
            userData.mobile = fields.mobile;
            userData.ethnicityId = fields.ethnicityId;
            userData.birthDate = fields.birthDate;
            userData.sexAtBirth = fields.sexAtBirth;
            userData.address = {
              streetAddress: fields.streetAddress.toUpperCase(),
              extendedAddress: fields.extendedAddress.toUpperCase(),
              provinceOrState: {
                name: fields.provinceOrState.toUpperCase()
              },
              city: {
                name: fields.city.toUpperCase()
              },
              country: fields.country.toUpperCase(),
              postalCode: fields.postalCode.toUpperCase()
            };
          userData.role = fields.role;
          userData.active = fields.active;

          await apiService.userinfoupdate(userData);
          setmsgToast('User updated successfully.');
          setReturnto(urlBase);
          setError(false);
          setOpenToast(true);
        } else {
          const addFields = {
            email: fields.email.toLowerCase(),
            firstName: fields.firstName.toUpperCase(),
            lastName: fields.lastName.toUpperCase(),
            role: fields.role
          };
          const existEmail = await apiService.verifyEmail({ param: String(fields.email.toLowerCase()) });
          if (existEmail.data) {
            setmsgToast('The email you entered already exists in our database.');
            setReturnto(null);
            setError(true);
            setOpenToast(true);
          } else {
            await apiService.useradd(addFields);
            setmsgToast('User added successfully.');
            setReturnto(urlBase);
            setError(false);
            setOpenToast(true);
            resetForm();
          }
        }
      } catch (error) {
        setmsgToast(error.message);
        setReturnto(null);
        setError(true);
        setOpenToast(true);
      }
    }
  };

  const handleCancel = async () => {
    navigate(urlBase);
  };

  return (
    <>
      <div className="home-container">

        <Box sx={{ display: 'flex' }}>
          <SideBar />
          <Box component="main" sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', p: 3 }}>
        <NavBar />

            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

              <div className="home-banner-container">
                <div className="home-text-section">
                  {!userParam && (
                    <div>
                  <h1 className="primary-heading">Your Information</h1>
                  <p className="primary-text">Please fill out the following information</p></div>)}
                  {userParam && (
                    <div>
                  <h1 className="primary-heading">Editing user</h1>
                  </div>)}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
                      <form onSubmit={handleSubmit} autoComplete='off'>

                        <TextField fullWidth label={`Email ${userIdCall === null & userIdCall !== '0' ? "(You can't change)" : ''}`} value={fields.email} onChange={e => handleChange(e, 'email')} margin="normal" required InputProps={{ readOnly: userIdCall > 0 ? true : false }} />
                        {emailError && <small className="text-danger">{emailError}</small>}
                        <TextField fullWidth label="First Name" value={fields.firstName} onChange={e => handleChange(e, 'firstName')} margin="normal" required autoComplete="off" />
                        <TextField fullWidth label="Last Name" value={fields.lastName} onChange={e => handleChange(e, 'lastName')} margin="normal" required />

                        {userIdCall > 0 && (
                          <div>
                            <TextField fullWidth label="Mobile" placeholder="xxx-xxx-xxxx" value={fields.mobile} onChange={e => handleChange(e, 'mobile')} margin="normal" />
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div style={{ marginRight: '50px', height: '90px' }}>
                                <InputLabel id='lbbirth'>Birth Date:</InputLabel>
                                <TextField style={{ marginTop: '0px', height: '100%', width: '100%' }} labelId='lbbirth' type='date' value={fields.birthDate} onChange={e => handleChange(e, 'birthDate')} margin="normal" required />
                              </div>
                              <div style={{ height: '90px' }}>
                                <InputLabel id='lbsex'>Sex At Birth</InputLabel>
                                <Select fullWidth labelId='lbsex' value={fields.sexAtBirth} onChange={e => handleChange(e, 'sexAtBirth')} required>
                                  <MenuItem value={"M"}>Male</MenuItem>
                                  <MenuItem value={"F"}>Female</MenuItem>
                                </Select>
                              </div>
                            </div>

                            <div style={{ height: '90px' }}>
                              <InputLabel id='lbeth'>Ethnicity</InputLabel>
                              <Select fullWidth labelId='lbeth' value={fields.ethnicityId} onChange={e => handleChange(e, 'ethnicityId')}>
                                <MenuItem value={"1"}>Black</MenuItem>
                                <MenuItem value={"2"}>East Asian</MenuItem>
                                <MenuItem value={"3"}>Latin/Hispanic</MenuItem>
                                <MenuItem value={"4"}>Middle Eastern</MenuItem>
                                <MenuItem value={"5"}>South Asian</MenuItem>
                                <MenuItem value={"6"}>Southeast Asian</MenuItem>
                                <MenuItem value={"7"}>White or Caucasian</MenuItem>
                              </Select>
                            </div>

                            <TextField fullWidth label="Street Address" value={fields.streetAddress} onChange={e => handleChange(e, 'streetAddress')} margin="normal" />
                            <TextField fullWidth label="Extended Address" value={fields.extendedAddress} onChange={e => handleChange(e, 'extendedAddress')} margin="normal" />

                            <Autocomplete
                              fullWidth
                              options={lstProvOrState}
                              value={fields.provinceOrState}
                              onChange={(event, newValue) => handleChange({ target: { value: newValue } }, 'provinceOrState')}
                              onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue, 'provinceOrState')}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Province Or State"
                                  margin="normal"
                                />
                              )}
                            />

                            <Autocomplete
                              fullWidth
                              options={lstCities}
                              value={fields.city}
                              onChange={(event, newValue) => handleChange({ target: { value: newValue } }, 'city')}
                              onInputChange={(event, newInputValue) => handleInputChange(event, newInputValue, 'city')}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Location"
                                  margin="normal"
                                />
                              )}
                            />

                            <div style={{ height: '90px' }}>
                              <InputLabel id='lbcountry'>Country</InputLabel>
                              <Select fullWidth labelId='lbcountry' value={fields.country} onChange={e => handleChange(e, 'country')} required>
                                <MenuItem value={"CA"}>CANADA</MenuItem>
                                <MenuItem value={"US"}>UNITED STATES</MenuItem>
                                <MenuItem value={"BR"}>BRAZIL</MenuItem>
                              </Select>
                            </div>
                            <TextField fullWidth label="Postal Code" value={fields.postalCode} onChange={e => handleChange(e, 'postalCode')} margin="normal" />

                            {userRole === "Admin" &&
                              <div style={{ height: '90px' }}>
                                <InputLabel id='lbrole'>Role</InputLabel>
                                <Select fullWidth labelId='lbrole' value={fields.role} onChange={e => handleChange(e, 'role')} required InputProps={{ readOnly: userRole !== "Admin" ? true : false }}>
                                  <MenuItem value={"1"}>Admin</MenuItem>
                                  <MenuItem value={"2"}>Partner</MenuItem>
                                  <MenuItem value={"3"}>Lab_Manager</MenuItem>
                                  <MenuItem value={"4"}>Client</MenuItem>
                                  <MenuItem value={"9"}>Undefined</MenuItem>
                                </Select>
                              </div>
                            }

                            {/*<Grid item xs={12}>
                              <FormControl component="fieldset">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={fields.active}
                                      onChange={e => handleChange(e, 'active')}
                                      name="active"
                                      color="primary"
                                    />
                                  }
                                  label="User is active"
                                />
                              </FormControl>
                            </Grid>*/}
                          </div>)}
                          <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button variant='contained' type="submit"
                              sx={{
                                width: "150px",
                                backgroundColor: "rgba(197, 220, 255, 1)",
                                color: "#000000",
                                fontWeight: "bold",
                                fontFamily: "Roboto",
                                borderColor: "rgba(197, 220, 255, 1)"
                              }} >
                              Submit
                            </Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Button
                                variant='contained'
                                type="button"
                                onClick={handleCancel}
                                sx={{
                                  width: "150px",
                                  backgroundColor: "rgba(197, 220, 255, 1)",
                                  color: "#000000",
                                  fontWeight: "bold",
                                  fontFamily: "Roboto",
                                  borderColor: "rgba(197, 220, 255, 1)"
                                }}
                              >
                                Cancel
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Box>
                </div>
              </div>
            </Container>
          </Box>
        </Box>
      </div>
      <Toast open={openToast} handleClose={handleCloseToast}
        message={msgToast}
        error={error}
/>
    </>
  )
};

export default UserInfo;
