import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import Container from '@mui/material/Container';
import { TextField, Button, Box, InputLabel, MenuItem, Select } from "@mui/material";
import FooterBar from "../shared/footer";
import Toast from "../shared/toast";
import { apiService } from '../services/apiService';
import { authService } from '../services/authService';
import { validator } from '../shared/validator';

const UserNew = () => {
  const [openToast, setOpenToast] = useState(false);
  const [msgToast, setmsgToast] = useState('');
  const [returnto, setReturnto] = useState('/');
  const [emailError, setEmailError] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [fields, setFields] = useState({
    email: '',
    firstName: '',
    lastName: '',
    id: 0,
    mobile: '',
    ethnicityId: '',
    birthDate: '',
    sexAtBirth: '',
    streetAddress: '',
    extendedAddress: '',
    provinceOrState: '',
    city: '',
    country: 'CA',
    postalCode: '',
    role: 9
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const userinfo = await authService.getUserData();

        if (userinfo && userinfo.role !== "Admin" && userinfo.role !== "Partner") {
          setmsgToast('You are not authorized to perform this action.');
          setError(true);
          setReturnto('/');
          setOpenToast(true);
        }
      } catch (error) {
        setmsgToast((error.message || 'Error occurred') + '<br/>Check the entered data.');
        setError(true);
        setReturnto(null);
        setOpenToast(true);
      }
    }

    fetchData();
  }, []);

  const handleChange = (e, field) => {
    setFields(prev => ({
      ...prev,
      [field]: e.target.value,
    }));
    setEmailError('');
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    if (returnto) {
      navigate(returnto);
    }
  };

  const resetForm = () => {
    setFields({
      email: '',
      firstName: '',
      lastName: '',
      id: '',
      mobile: '',
      ethnicityId: '',
      birthDate: '',
      sexAtBirth: '',
      streetAddress: '',
      extendedAddress: '',
      provinceOrState: '',
      city: '',
      country: 'CA',
      postalCode: '',
      role: 9
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailValidationMsg = validator.validateEmail(fields.email);
    setEmailError(emailValidationMsg);
    if (emailValidationMsg === '') {
      try {
        const addFields = {
          email: fields.email.toLowerCase(),
          firstName: fields.firstName.toUpperCase(),
          lastName: fields.lastName.toUpperCase(),
          role: fields.role
        };
        const existEmail = await apiService.verifyEmail({ param: String(fields.email.toLowerCase()) });
        console.log('Exist Email Response:', existEmail); // Add this line
        if (existEmail.data) {
          setmsgToast('The email you entered already exists in our database.');
          setError(true);
          setReturnto(null);
          setOpenToast(true);
        } else {
          await apiService.useradd(addFields);
          setmsgToast('Thank you for your interest.<br/>We will contact you to complete your registration.');
          setReturnto('/');
          setError(false);
          setOpenToast(true);
          resetForm();
        }
      } catch (error) {
        setmsgToast(error.message);
        setError(true);
        setReturnto(null);
        setOpenToast(true);
      }
    }
  };

  return (
    <>
      <div className="home-container">
        <NavBar />
        <Box sx={{ display: 'flex' }}>
          <SideBar />
          <Box component="main">
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <div className="home-banner-container">
                <div className="home-text-section">
                  <h1 className="primary-heading">Add New User</h1>
                  <p className="primary-text">Please fill out the following information</p>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
                      <form onSubmit={handleSubmit} autoComplete='off'>
                        <TextField fullWidth label={"Email"} value={fields.email} onChange={e => handleChange(e, 'email')} margin="normal" required />
                        {emailError && <small className="text-danger">{emailError}</small>}
                        <TextField fullWidth label="First Name" value={fields.firstName} onChange={e => handleChange(e, 'firstName')} margin="normal" required autoComplete="off" />
                        <TextField fullWidth label="Last Name" value={fields.lastName} onChange={e => handleChange(e, 'lastName')} margin="normal" required />
                        <div style={{ height: '90px' }}>
                                <InputLabel id='lbrole'>Role</InputLabel>
                                <Select fullWidth labelId='lbrole' value={fields.role} onChange={e => handleChange(e, 'role')} required>
                                  <MenuItem value={"1"}>Admin</MenuItem>
                                  <MenuItem value={"2"}>Partner</MenuItem>
                                  <MenuItem value={"3"}>Lab_Manager</MenuItem>
                                  <MenuItem value={"4"}>Client</MenuItem>
                                  <MenuItem value={"9"}>Undefined</MenuItem>
                                </Select>
                              </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <Button variant='contained' type="submit" sx={{ mt: 2 }} style={{ align: "center", backgroundColor: "#1789C6", height: "60px", width: "150px", color: "white", borderBlockColor: "#1789C6", borderBlock: "0px", fontWeight: 'bold', margin: "1.5rem", borderRadius: "50px" }} >
                            Submit
                          </Button>
                        </div>
                      </form>
                    </Box>
                  </Box>
                </div>
              </div>
            </Container>
          </Box>
        </Box>
      </div>
      <FooterBar />
      <Toast open={openToast} handleClose={handleCloseToast}
        message={msgToast}
        error={error} />
    </>
  );
};

export default UserNew;
