import React, { useState, useEffect, useCallback } from 'react';
import NavBar from '../shared/navBar';
import SideBar from '../shared/sideBar';
import {
  Container, Grid, Stack, Button, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
  TableSortLabel, CssBaseline, Typography, TextField, InputAdornment, IconButton, Checkbox, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { apiService } from '../services/apiService';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import EditNote from '@mui/icons-material/EditNote';
import PartnerScanKit from './PartnerScanKit';
import DialogPartner from './DialogPartner';
import { Refresh } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import QrCodeScanner from '@mui/icons-material/QrCodeScanner';

const ManagerPartner = () => {
  const [partnererror, setPartnererror] = useState('');
  const [lstPartners, setLstPartnerAll] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [partnerId, setPartnerId] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);  // Use React state for pagination
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [searchQuery, setSearchQuery] = useState('');
  const [showOnlyActive, setShowOnlyActive] = useState(true);
  const [openInfoModal, setOpenInfoModal] = useState(false);  // State for partner info modal
  const [openScanKitModal, setOpenScanKitModal] = useState(false);  // State for scan kit modal
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (sessionStorage.getItem('searchQuery')) {
        setSearchQuery(sessionStorage.getItem('searchQuery'));  // Restore the search query
      }
      const storedActive = sessionStorage.getItem('showOnlyActive');
      if (storedActive !== null) {
        setShowOnlyActive(storedActive === 'true');
      }
      if (sessionStorage.getItem('page')) {
        setPage(Number(sessionStorage.getItem('page')));
      }

      await fetchData();  // Fetch the data asynchronously
    };

    fetchInitialData();  // Call the async function

  }, []);

  useEffect(() => {
    const filterAndSortData = async () => {
      filterAndSortPartners(lstPartners);
    };

    filterAndSortData();  // Call the function
  }, [showOnlyActive, page, rowsPerPage, order, orderBy]);


  async function fetchData() {
    try {
      const partners = await apiService.partnerall();
      if (Array.isArray(partners.data)) {
        const names = partners.data.map(item => ({
          id: item.id,
          name: item.name,
          city: item.partnerAddress ? item.partnerAddress.city.name : '',
          partnerType: item.partnerType.type,
          active: item.active
        }));
        setLstPartnerAll(names);
        filterAndSortPartners(names);
      }
    } catch (error) {
      console.error('Error fetching partner types.', error);
    }
  }

  const handleShowOnlyActiveChange = (event) => {
    const isChecked = event.target.checked;
    setShowOnlyActive(isChecked);
    sessionStorage.setItem('showOnlyActive', String(isChecked));  // Store as string in sessionStorage
    handleClearSearch();
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    sessionStorage.setItem('page', String(newPage));
    setPage(Number(sessionStorage.getItem('page')));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenInfoModal = (id) => {
    sessionStorage.setItem('page', String(page));  // Save current page number
    setPage(Number(sessionStorage.getItem('page')));
    sessionStorage.setItem('showOnlyActive', String(showOnlyActive));  // Save checkbox state
    sessionStorage.setItem('searchQuery', searchQuery);
    setPartnerId(id);
    setOpenInfoModal(true);  // Open partner info modal
  };

  const handleOpenScanKitModal = (id) => {
    sessionStorage.setItem('page', String(page));  // Save current page number
    setPage(Number(sessionStorage.getItem('page')));
    sessionStorage.setItem('showOnlyActive', String(showOnlyActive));  // Save checkbox state
    sessionStorage.setItem('searchQuery', searchQuery);
    setPartnerId(id);
    setOpenScanKitModal(true);
  };

  const handleCloseModal = () => {
    setOpenInfoModal(false);
    fetchData();
  };

  const handleCloseScankitModal = () => {
    setOpenScanKitModal(false);
  };

  const handleClearSearch = () => {
    let filteredList = lstPartners;
    setSearchQuery('');
    if (showOnlyActive) {
      filteredList = filteredList.filter(partner => partner.active);
    }
    setFilteredPartners(filteredList);
    filterAndSortPartners(filteredList);
  };

  const handleSearchChange = (event) => {
    if (event.key === 'Enter') {
      const query = event.target.value;
      setSearchQuery(query);
      handleSearch();
    }
  };

  const handleSearch = async () => {
    let filteredList = lstPartners;
    sessionStorage.setItem('page', '0');
    setPage(Number(sessionStorage.getItem('page')));
    if (searchQuery) {
      filteredList = filteredList.filter(partner =>
        partner.name.toUpperCase().includes(searchQuery.toUpperCase())
      );
    }
    if (showOnlyActive) {
      filteredList = filteredList.filter(partner => partner.active);
    }
    setFilteredPartners(filteredList);
    filterAndSortPartners(filteredList);
  };

  const filterAndSortPartners = useCallback((partnersList) => {
    let filteredList = partnersList;

    if (showOnlyActive) {
      filteredList = filteredList.filter(partner => partner.active);
    }

    filteredList = filteredList.sort((a, b) => {
      if (orderBy === 'name') {
        return order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      }
      if (orderBy === 'city') {
        return order === 'asc' ? a.city.localeCompare(b.city) : b.city.localeCompare(a.city);
      }
      if (orderBy === 'partnerType') {
        return order === 'asc' ? a.partnerType.localeCompare(b.partnerType) : b.partnerType.localeCompare(a.partnerType);
      }
      if (orderBy === 'active') {
        return order === 'asc' ? (a.active === b.active ? 0 : a.active ? -1 : 1) : (a.active === b.active ? 0 : a.active ? 1 : -1);
      }
      return 0;
    });

    const paginatedList = filteredList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    setFilteredPartners(paginatedList);
    setFilteredList(filteredList);
  }, [showOnlyActive, orderBy, order, page, rowsPerPage]);

  return (
    <>
      <div className="bgColor">
        <Box sx={{ display: 'flex' }}>
          <SideBar />
          <CssBaseline />
          <Box component="main" sx={{ flexGrow: 1, overflow: 'visible', height: '100vh', p: 3 }}>
            <NavBar />
            <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h4" sx={{ mb: 2 }}>Partners</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Search Partner"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    onKeyDown={handleSearchChange}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton color="primary" onClick={filterAndSortPartners}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: searchQuery && (
                        <InputAdornment position="end">
                          <IconButton color="primary" onClick={handleClearSearch}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: "10px" }}>
                <Tooltip title="Click to Add a New Partner">
                  <IconButton
                    onClick={() => handleOpenInfoModal('0')}
                    sx={{
                      backgroundColor: "rgba(197, 220, 255, 1)",
                      color: "#000000",
                      fontWeight: "bold",
                      borderColor: "rgba(197, 220, 255, 1)",
                      "&:hover": {
                        backgroundColor: "rgba(150, 200, 255, 1)"
                      }
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
                </Grid>

                <Grid item xs={4}>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end">
                    <Typography variant="body1">Show Active Only</Typography>
                    <Checkbox
                      checked={showOnlyActive}
                      onChange={handleShowOnlyActiveChange}
                      color="primary"
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} sx={{ mt: 2 }}>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {['name', 'city', 'partnerType', 'active'].map((field) => (
                              <TableCell key={field} sortDirection={orderBy === field ? order : false} sx={{ fontSize: '0.875rem', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
                                <TableSortLabel
                                  active={orderBy === field}
                                  direction={orderBy === field ? order : 'asc'}
                                  onClick={(event) => handleRequestSort(event, field)}
                                >
                                  {field.charAt(0).toUpperCase() + field.slice(1)}
                                </TableSortLabel>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredPartners.map((partner) => (
                            <TableRow key={partner.id}>
                              <TableCell>{partner.name}</TableCell>
                              <TableCell>{partner.city}</TableCell>
                              <TableCell>{partner.partnerType}</TableCell>
                              <TableCell>{partner.active ? <CheckIcon sx={{ color: 'green' }} /> : <CloseIcon sx={{ color: 'red' }} />}</TableCell>
                              <TableCell>
                                <Tooltip title="Click to Update Partner Info">
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleOpenInfoModal(partner.id)}
                                  >
                                    <EditNote style={{ width: 24, height: 24 }} />
                                  </Button>
                                </Tooltip>
                                <Tooltip title="Click to Assign Tests Kits to Partner">
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={() => handleOpenScanKitModal(partner.id)}
                                  >
                                     <QrCodeScanner/>
                                  </Button>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <TablePagination
                        component="div"
                        count={filteredList.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[]}  // Disable the rows per page options
                        labelRowsPerPage=""       // Hide the "Rows per page" label
                        sx={{
                          '& .MuiTablePagination-selectLabel': {
                            display: 'none',      // Hide the "Rows per page" text
                          },
                          '& .MuiTablePagination-displayedRows': {
                            display: 'none',      // Hide the "6–10 of 22" text
                          },
                          '& .MuiInputBase-root': {
                            display: 'none',      // Hide the rows per page dropdown
                          },
                        }}
                      />
                    </TableContainer>
                    {partnererror && <small className="text-danger">{partnererror}</small>}
                  
                </Grid>
              </Grid>
              </Paper>
            </Container>
          </Box>
        </Box>
      </div>
      {/* Partner Info Modal */}
      <Dialog open={openInfoModal} 
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleCloseModal(); // Call your close handler only if it's not a backdrop click
        }
    }} 
    disableEscapeKeyDown={true}
      
      fullWidth maxWidth="md">
        <DialogTitle>Partner Info</DialogTitle>
        <DialogContent>
          {/* Load your partnerinfo component here */}
          <DialogPartner partnerId={String(partnerId)} handleCloseModal={handleCloseModal} />
        </DialogContent>
        <DialogActions>

        </DialogActions>
      </Dialog>

      {/* Scan Kit Modal */}
      <Dialog open={openScanKitModal}
      
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleCloseScankitModal(); // Call your close handler only if it's not a backdrop click
        }
    }} 
    disableEscapeKeyDown={true}
      
      fullWidth maxWidth="md">
        <DialogContent>
          {/* Load your partnerscankit component here */}
          <PartnerScanKit partnerId={String(partnerId)} handleCloseScankitModal={handleCloseScankitModal} />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ManagerPartner;

