import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

function Toast({ open, handleClose, message, error }) {
  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };
  
  return (
    <Snackbar
    open={open}
    autoHideDuration={1000}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'center',
    }}
    style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }}
  >
      <Alert
        onClose={handleClose}
        severity={error ? "error" : "success"}
        variant="filled"        
      >
<div style={{padding: 30}} dangerouslySetInnerHTML={createMarkup(message)} />
      </Alert>
    </Snackbar>
  );
}

export default Toast;
