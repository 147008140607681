import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { authService } from '../services/authService';
import Toast from '../shared/toast';
const KillSession = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showWarning, setShowWarning] = useState(false);
    let inactivityTimer;
    let warningTimer;

    const logoutDueToInactivity = () => {
        authService.logOut(); // Clear localStorage/sessionStorage
        navigate('/login');    // Redirect to login page
    };

    const showInactivityWarning = () => {
        if (location.pathname !== '/login') { // Do not show the message if on the login page
            setShowWarning(true); // Show the warning message
        }
    };

    const resetInactivityTimer = () => {
        // Clear the previous timers if any

        if (inactivityTimer) clearTimeout(inactivityTimer);
        if (warningTimer) clearTimeout(warningTimer);

        // Hide the warning message if the user is active
        setShowWarning(false);

        // Set the inactivity warning timer for 55 minutes (3300000 ms)
        warningTimer = setTimeout(showInactivityWarning, 3300000); // Show warning after 55 minutes

        // Set the inactivity timer for 1 hour (3600000 ms)
        inactivityTimer = setTimeout(logoutDueToInactivity, 3600000); // Logout after 1 hour
    };

    useEffect(() => {
        // Reset inactivity timer on user activity
        const events = ['mousemove', 'keydown', 'click', 'touchstart'];
        events.forEach(event => {
            window.addEventListener(event, resetInactivityTimer);
        });

        // Set the initial timer when the component is mounted
        resetInactivityTimer();

        // Clean up event listeners and timers on component unmount
        return () => {
            events.forEach(event => {
                window.removeEventListener(event, resetInactivityTimer);
            });
            clearTimeout(inactivityTimer);
            clearTimeout(warningTimer);
        };
    }, [navigate]);

    return (
        <>
            {showWarning && (


<Toast
                open={showWarning}

                message="You have been inactive for a while. You will be logged out in 5 minutes due to inactivity."
                error={true}
                autoHideDuration={100000}
            />

            )}
        </>
    );
};

export default KillSession;
