import { useEffect, useState } from "react";
import Toast from "../shared/toast";
import { apiService } from "../services/apiService";
import { authService } from "../services/authService";
import BannerBackground from "../assets/vector-8.png";
import BannerImage from "../assets/Login_img.png";
import { TextField, Button, Box, Grid, FormControlLabel, Checkbox, Stack } from "@mui/material";
import FooterBar from "../shared/footer";
import { useNavigate, useParams } from 'react-router-dom';

const ResetPassword = () => {
    const [linkto, setlinkto] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const [password2, setPassword2] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [password2Error, setPassword2Error] = useState("");
    const navigate = useNavigate();
    const [openToast, setOpenToast] = useState(false);
    const [msgToast, setmsgToast] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError]= useState(false);
    const { usertoken } = useParams();
    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        const response = await authService.getDatabyToken(usertoken);
        setEmail(response.data.email);
        setToken(usertoken);
    };

    const validatePassword = (newPassword) => {
        if (newPassword.length < 8) {
            return 'Password must be at least 8 characters long';
        } else if (!/[A-Z]/.test(newPassword)) {
            return 'Password must contain at least one uppercase letter';
        } else if (!/[a-z]/.test(newPassword)) {
            return 'Password must contain at least one lowercase letter';
        } else if (!/\d/.test(newPassword)) {
            return 'Password must contain at least one digit';
        } else {
            return '';
        }
    };

    const submitForm = async (event) => {
        event.preventDefault();
        const passError = validatePassword(password)
        setPasswordError(passError);
        if (passError === "") {
            if (password !== password2) {
                setPassword2Error('* Passwords don´t match');
            }
            else {
                setPassword2Error('');
                if (email) {
                    setSubmitted(true);
                    try {
                        await apiService.newpassword({ email, password, token });
                        setmsgToast('Your password was changed.<br/>Now you can log in with new password.');
                        setError(false);
                        setlinkto('/login');
                    }
                    catch (error) {
                        setmsgToast('An error occurred while changing your password.<br/>' + error.message);
                        setlinkto('');
                        setError(true);
                        setSubmitted(false);
                    }
                }
                setOpenToast(true);
            }
        }
    };

    const handleCloseToast = async () => {
        setOpenToast(false);
        if (linkto !== '') {
            navigate(linkto);
        }
        else {
            await apiService.mailresetpassword(email);
        }
    };

    return (
        <>
            <div className="home-container">

                <div className="home-banner-container">
                    <div className="home-bannerImage-container">
                        <img src={BannerBackground} alt="" />
                    </div>
                    <div className="home-text-section">
                        <h1 className="primary-heading">
                            New Password</h1>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
                                <form onSubmit={submitForm}>
                                    <TextField
                                        fullWidth
                                        label="Email (You can't change)"
                                        value={email}
                                        margin="normal"
                                        type='email'
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        value={password} onChange={(e) => setPassword(e.target.value)}
                                        margin="normal"
                                        required
                                    />
                                    {passwordError && <small className="text-danger">{passwordError}</small>}
                                    <TextField
                                        fullWidth
                                        label="Retype Password"
                                        type="password"
                                        value={password2} onChange={(e) => setPassword2(e.target.value)}
                                        margin="normal"
                                        required
                                    />
                                    {password2Error && <small className="text-danger">{password2Error}</small>}

                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px" }}>
                                        <ul style={{ listStyleType: "initial", padding: 0, textAlign: "left" }}>
                                            <li>Password must be at least 8 characters long</li>
                                            <li>Password must contain at least one uppercase letter</li>
                                            <li>Password must contain at least one lowercase letter</li>
                                            <li>Password must contain at least one digit</li>
                                        </ul>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Button variant='contained' type="submit" disabled={submitted} sx={{ mt: 2 }} style={{ align: "center", backgroundColor: "#1789C6", height: "60px", width: "150px", color: "white", borderBlockColor: "#1789C6", borderBlock: "0px", fontWeight: 'bold', margin: "1.5rem", borderRadius: "50px" }} >
                                            Save
                                        </Button>
                                    </div>
                                </form>
                            </Box>
                        </Box>
                    </div>
                    <div className="home-image-section">
                        <img src={BannerImage} alt="" />
                    </div>
                </div>
            </div>
            <FooterBar />
            <Toast open={openToast} handleClose={handleCloseToast}
                message={msgToast}
                error={error}
                 />
        </>

    );
}

export default ResetPassword;