import React, { useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';

import { useNavigate } from 'react-router-dom';
import { SidebarContext } from '../context/SidebarContext';
import { authService } from '../services/authService';
import { AccountBox, Biotech, FolderShared, Groups, ViewList } from '@mui/icons-material';
import LiveAgent from "../liveAgent";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const SideBar = () => {
  const { isCollapsed, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const userData = authService.getUserData();

  if (!userData) {
    return null; // ou uma mensagem de erro, ou redirecionamento para login
  }

  //usado por outras páginas para zerar session on load
  sessionStorage.setItem('searchQuery', '');
  sessionStorage.setItem('showOnlyActive', 'true');
  sessionStorage.setItem('page', '0');

  const { role } = userData;

  const renderListItem = (role, requiredRoles, text, icon, onClick) => {
    if (requiredRoles.includes(role)) {
      return (
        <ListItem disablePadding sx={{ display: 'block', height: 60 }} onClick={onClick}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: isCollapsed ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isCollapsed ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              {icon}
            </ListItemIcon>
            <ListItemText
              primary={text}
              primaryTypographyProps={{ fontSize: '16px', fontWeight: "bold" }}
              sx={{ opacity: isCollapsed ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      );
    }
    return null;
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        open={isCollapsed}
        PaperProps={{
          sx: {
            backgroundColor: "#C5DCFF",
            color: "#000000",
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={toggleSidebar}>
            {isCollapsed ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ backgroundColor: "#C5DCFF", color: "#000000" }}>

          {renderListItem(role, ['Client'], 'Welcome', <GroupsIcon style={{ color: "#000000" }} />, () => navigate("/clientdash"))}  
          {renderListItem(role, ['Admin'], 'Manage Partner', <HandshakeOutlinedIcon style={{ color: "#000000" }} />, () => navigate("/managepartner"))}
          {renderListItem(role, ['Admin','Partner', 'AuthorizedUser'], 'Manage Clients', <GroupsIcon style={{ color: "#000000" }} />, () => navigate("/manageclient"))}
          {renderListItem(role, ['Admin', 'Partner'], 'Manage Users', <GroupsIcon style={{ color: "#000000" }} />, () => navigate("/manageuser"))}
          {renderListItem(role, ['Admin', 'Partner', 'AuthorizedUser'], 'Activate Test', <FactCheckOutlinedIcon style={{ color: "#000000" }} />, () => navigate("/testActivate"))}
          {renderListItem(role, ['Lab_Manager'], 'Kits Receipt', <Biotech style={{ color: "#000000" }} />, () => navigate("/labreceipt"))}
          {renderListItem(role, ['Admin','Lab_Manager'], 'Test Result', <Biotech style={{ color: "#000000" }} />, () => navigate("/managetestresults"))}
          {/*renderListItem(role, ['Admin'], 'Test Score', <Biotech style={{ color: "#000000" }} />, () => navigate("/testscore"))*/}
          {renderListItem(role, ['Admin', 'Partner', 'AuthorizedUser'], 'Test Progress', <AutoGraphOutlinedIcon style={{ color: "#000000" }} />, () => navigate("/testsProgress"))}
          {renderListItem(role, ['Partner'], 'Your Business', <CorporateFareOutlinedIcon style={{ color: "#000000" }} />, () => navigate("/partnerinfo"))}
          {renderListItem(role, ['Client'], 'Questionnaire', <GroupsIcon style={{ color: "#000000" }} />, () => navigate("/questionnaire"))}  

          {/*renderListItem(role, ['Admin'], 'Dashboard', <EqualizerIcon style={{ color: "#000000" }} />, () => navigate("/admindash"))*/}
          {/*renderListItem(role, ['Admin'], 'View Partners', <ViewList style={{ color: "#000000" }} />, () => navigate("/partnerview"))*/}
          {/*renderListItem(role, ['Admin', 'Partner'], 'Manage Lab Users', <ScienceIcon style={{ color: "#000000" }} />, () => navigate("/manage-lab-users"))*/}
          {/*renderListItem(role, ['Admin', 'Lab_Manager'], 'Manage Test Result', <BiotechIcon style={{ color: "#000000" }} />, () => navigate("/managetestresult"))*/}
          {/*renderListItem(role, ['Client'], 'Dashboard', <EqualizerIcon style={{ color: "#000000" }} />, () => navigate("/clientdash"))*/}
          {/*renderListItem(role, ['Partner'], 'Dashboard', <EqualizerIcon style={{ color: "#000000" }} />, () => navigate("/partnerdash"))*/}

          <Divider sx={{ my: 2 }} />
          {renderListItem(role, ['Admin', 'Partner', 'AuthorizedUser', 'Lab_Manager', 'Client'], 'Your Info', <PersonIcon style={{ color: "#000000" }} />, () => navigate("/userinfo"))}
        </List>
      </Drawer>
<div><LiveAgent/></div>
    </Box>
  );
};

export default SideBar;
