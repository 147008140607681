import { Navigate } from "react-router-dom";

const getUserRole = () => {
  return localStorage.getItem('role') || sessionStorage.getItem('role');
};

export const RoleBasedRoute = ({ children, allowedRoles, allowPublic = false }) => {
  const userRole = getUserRole();

  if (allowPublic || allowedRoles.includes('Public') || (userRole && allowedRoles.includes(userRole))) {
    return children;
  }

  return <Navigate to="/notfound" />;
};
