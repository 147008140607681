
import axios from 'axios';

const response = await fetch('appconfig.json');
const appconfig = await response.json();

const axiosInstance = axios.create({
  baseURL: appconfig.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    if(!config.headers['Authorization']){
    const staticToken = appconfig.REACT_APP_SECRETKEY;
    config.headers['Authorization'] = staticToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
