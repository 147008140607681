import './DialogPartner.css';
import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, InputLabel, MenuItem, Select, FormControl, FormControlLabel, Checkbox, Grid, Paper, Container } from "@mui/material";
import Toast from "../shared/toast";
import { apiService } from '../services/apiService';
import Autocomplete from '@mui/material/Autocomplete';
import LogoImage from "../assets/logo_partner.png";
import { validator } from "../shared/validator";

const DialogPartner = ({ partnerId, handleCloseModal }) => {
    const [msgToast, setmsgToast] = useState('');
    const [openToast, setOpenToast] = useState(false);
    const [error, setError] = useState(false);
    const [lstProvOrState, setlstProvOrState] = useState([]);
    const [lstPartnerType, setlstPartnerType] = useState([]);
    const [lstCities, setlstcities] = useState([]);
    const [addressId, setAddressId] = useState(null);
    const [logo, setLogo] = useState(null);
    const [countries, setCountries] = useState([]);

    const initialState = {
        name: "",
        partnerType: {
            type: ""
        },
        partnerAddress: {
            streetAddress: "",
            extendedAddress: "",
            postalCode: "",
            country: "CA",
            provinceOrState: {
                name: ""
            },
            city: {
                name: ""
            }
        },
        active: true
    };

    const [fields, setFields] = useState(initialState);
    const [defaultCountry, setDefaultCountry] = useState('CA');

    useEffect(() => {
        async function fetchData() {
            try {
                const fetchedCountries = [
                    { name: "Canada", code: "CA" },
                    { name: "Brazil", code: "BR" },
                    { name: "United States", code: "US" },
                ];
                setCountries(fetchedCountries);

                const [partnertypes, provstates, cities] = await Promise.all([
                    apiService.partnertypeall(),
                    apiService.provinceorstateall(),
                    apiService.citiesall()
                ]);

                const mapData = (data, key) => data.map(item => item[key]);

                setlstPartnerType(mapData(partnertypes.data, 'type'));
                setlstProvOrState(mapData(provstates.data, 'name'));
                setlstcities(mapData(cities.data, 'name'));

                if (partnerId !== '0') {
                    const partnermailData = { param: partnerId };
                    const [partneradm, partner] = await Promise.all([
                        apiService.admbypartnerid(partnermailData),
                        apiService.partnerbyid(partnerId)
                    ]);

                    setAddressId(partner.data.addressId);

                    setFields({
                        id: partner.data.id,
                        active: partner.data.active,
                        email: partneradm?.data?.email || '',
                        name: partner.data.name,
                        partnerType: partner.data.partnerType.type,
                        streetAddress: partner?.data?.partnerAddress?.streetAddress || '',
                        extendedAddress: partner?.data?.partnerAddress?.extendedAddress || '',
                        provinceOrState: partner?.data?.partnerAddress?.provinceOrState?.name || '', // Fix this line
                        city: partner?.data?.partnerAddress?.city?.name || '', // Fix this line
                        country: partner?.data?.partnerAddress?.country || 'CA', // Fix this line
                        postalCode: partner?.data?.partnerAddress?.postalCode || '',
                        site: partner?.data?.site || '',
                        logo: partner?.data?.logo || ''
                    });
                }
                else {
                    // Set the default country to 'CA' after the countries are fetched
                    setFields(prevFields => ({
                        ...prevFields,
                        country: 'CA' // Default country to Canada
                    }));
                }
            } catch (error) {
                setmsgToast(error.message);
                setError(true);
                setOpenToast(true);
            }
        }

        fetchData();
    }, [partnerId]);


    const handleLogoChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const file64 = await readFileAsBase64(file);
            setFields(prev => ({
                ...prev,
                logo: file64,
            }));
            setLogo(file);
        }
    };

    const handleChange = (e, field, newValue = null) => {
        setFields(prev => ({
            ...prev,
            [field]: field === 'active' ? e.target.checked : (newValue !== null ? newValue : e.target.value),
        }));
    };



    const validateFields = () => {
        const errors = [];

        if (!fields.email) {
            errors.push('Email');
        }

        if (!fields.name) {
            errors.push('Business Name');
        }

        if (fields.partnerType === null || fields.partnerType === '') {
            errors.push('Business Type');
        }

        if (!fields.provinceOrState) {
            errors.push('Province or State');
        }

        if (!fields.city) {
            errors.push('City');
        }

        if (!fields.country) {
            errors.push('Country');
        }


        const emailValidationMsg = validator.validateEmail(fields.email);
        if (emailValidationMsg) {
            setmsgToast(emailValidationMsg);
            setError(true);
            setOpenToast(true);
            return false; // Validation failed
        }

        if (errors.length > 0) {
            if (errors.includes('Email')) {
            }
            else {
                setmsgToast('This fields are required: ' + errors.join(', ')); // Combine error messages  
            }

            setError(true);
            setOpenToast(true);
            return false; // Validation failed
        }

        return true; // Validation passed
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate fields before submission
        if (!validateFields()) {
            return; // Stop if validation fails
        }

        try {
            const setDefault = (value) => (value === null || value === undefined ? '' : value);

            const updatedFields = {
                id: setDefault(partnerId),
                active: setDefault(fields.active),
                email: setDefault(fields.email?.toLowerCase() || ''),
                name: setDefault(fields.name?.toUpperCase() || ''),
                partnerType: {
                    type: setDefault(fields.partnerType?.toUpperCase() || ''),
                },
                partnerAddress: {
                    streetAddress: setDefault(fields.streetAddress || ''),
                    extendedAddress: setDefault(fields.extendedAddress || ''),
                    provinceOrState: {
                        name: setDefault(fields.provinceOrState?.toUpperCase() || ''),
                    },
                    city: {
                        name: setDefault(fields.city?.toUpperCase() || ''),
                    },
                    country: setDefault(fields.country || ''),
                    postalCode: setDefault(fields.postalCode || ''),
                },
                logo: setDefault(fields.logo || ''),
                site: setDefault(fields.site?.toLowerCase() || ''),
            };

            if (partnerId !== '0') {
                updatedFields.Id = Number(partnerId);
                updatedFields.partnerAddress.Id = Number(addressId);
            }

            await apiService.partneradd(updatedFields);
            setError(false);
            setmsgToast('Data updated successfully.');
            setOpenToast(true);

        } catch (error) {
            setError(true);
            setmsgToast(error.response.data.message);
            setOpenToast(true);
        }
    };


    const readFileAsBase64 = async (logo) => {
        if (logo.size > 100000) {
            setError(true);
            setmsgToast('The file is very large.<br/>Reduce the file size and try again.');
            setOpenToast(true);
        }
        else {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (event) => {
                    const arrayBuffer = event.target.result;
                    const bytes = new Uint8Array(arrayBuffer);
                    const base64String = btoa(String.fromCharCode.apply(null, bytes));
                    resolve(base64String);
                };
                reader.onerror = (error) => {
                    reject(error);
                };
                reader.readAsArrayBuffer(logo);
            });
        }
    };

    const handleCloseToast = () => {
        setOpenToast(false);
        if (!error) {
            handleCloseModal();
        }
    };

    return (
        <>
            <div >
                <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                value={fields.email || ''}
                                onChange={e => handleChange(e, 'email')}
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Business Name"
                                value={fields.name || ''}
                                onChange={e => handleChange(e, 'name')}
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                fullWidth
                                options={lstPartnerType}
                                value={lstPartnerType.find(option => option === fields.partnerType) || null}
                                onChange={(event, newValue) => handleChange({ target: { value: newValue } }, 'partnerType')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Business Type"
                                        margin="normal"
                                        required
                                    />
                                )}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Street Address"
                                value={fields.streetAddress || ''}
                                onChange={e => handleChange(e, 'streetAddress')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Extended Address"
                                value={fields.extendedAddress || ''}
                                onChange={e => handleChange(e, 'extendedAddress')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                fullWidth
                                options={lstProvOrState}
                                value={lstProvOrState.find(option => option === fields.provinceOrState) || null}
                                onChange={(event, newValue) => handleChange({ target: { value: newValue } }, 'provinceOrState')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Province Or State"
                                        margin="normal"
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                fullWidth
                                options={lstCities}
                                value={lstCities.find(option => option === fields.city) || null}
                                onChange={(event, newValue) => handleChange({ target: { value: newValue } }, 'city')}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Location"
                                        margin="normal"
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                fullWidth
                                options={countries}  // Your list of country objects with name and code
                                value={countries.find(option => option.code === fields.country) || null} // Match by country code
                                onChange={(event, newValue) => handleChange({ target: { value: newValue ? newValue.code : '' } }, 'country')} // Update the form field with country code
                                getOptionLabel={(option) => option?.name || ''} // Return the name of the country for display
                                renderInput={(params) => (
                                    <TextField
                                        {...params} // Spread the Autocomplete params to the TextField
                                        label="Country"
                                        margin="normal"
                                        required
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Postal Code"
                                value={fields.postalCode || ''}
                                onChange={e => handleChange(e, 'postalCode')}
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Website"
                                value={fields.site || ''}
                                onChange={e => handleChange(e, 'site')}
                                margin="normal"
                            />
                        </Grid>

                        <Grid container spacing={3} className='griduploadimage' >
                            <Grid item xs={12} md={6} className='imageupload'>
                                <input
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    id="contained-button-file"
                                    type="file"
                                    onChange={handleLogoChange}
                                />

                                <Grid spacing={6}>
                                    <label htmlFor="contained-button-file">
                                        <Button variant="contained" component="span" style={{
                                            backgroundColor: "#1789C6",
                                            height: "40px",
                                            width: "150px",
                                            color: "white",
                                            fontWeight: 'bold',
                                            borderRadius: "20px"
                                        }}>
                                            Upload Image
                                        </Button>
                                    </label>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={6} className='imageupload'>
                                <div style={{ marginBottom: 'auto' }}>
                                    <img src={fields.logo ? `data:image/png;base64,${fields.logo}` : LogoImage} className="logo-image-lg" />
                                </div>
                                <div style={{ marginTop: 'auto' }}>
                                    {logo && (
                                        <p className='smalltext'>{logo.name} - Size: {logo.size} bytes</p>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={fields.active}
                                            onChange={e => handleChange(e, 'active')}
                                            name="active"
                                            color="primary"
                                        />
                                    }
                                    label="Partner is active"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            mt: 2
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button
                                        variant='contained'
                                        type="submit"
                                        onClick={handleSubmit}
                                        sx={{
                                            backgroundColor: "rgba(197, 220, 255, 1)",
                                            color: "#000000",
                                            fontWeight: "bold",
                                            fontFamily: "Roboto",
                                            borderColor: "rgba(197, 220, 255, 1)"
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button
                                        onClick={handleCloseModal}
                                        sx={{
                                            backgroundColor: "rgba(197, 220, 255, 1)",
                                            color: "#000000",
                                            fontWeight: "bold",
                                            fontFamily: "Roboto",
                                            borderColor: "rgba(197, 220, 255, 1)"
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </div>
            <Toast
                open={openToast}
                handleClose={handleCloseToast}
                message={msgToast}
                error={error}
            />
        </>
    );
};

export default DialogPartner;
