import Toast from "../shared/toast";
import BannerBackground from "../assets/vector-8.png";
import BannerImage from "../assets/Login_img.png";
import { TextField, Button, Box, Grid, Stack } from "@mui/material";
import FooterBar from "../shared/footer";
import { useNavigate, NavLink } from 'react-router-dom';
import { useEffect } from "react";
import { authService } from "../services/authService";
import '../global.css';
import './Home.css';
import NavBar from '../shared/navBar';
import { useState } from "react";
import LiveAgent from "../liveAgent";
import { Label } from "@mui/icons-material";

const HomePage = () => {
    const navigate = useNavigate();
    const [logged, setLogged] = useState(false);

    useEffect(() => {
        fetchData();

        async function fetchData() {
            const isLoggedIn = await authService.isLoggedIn();
            setLogged(isLoggedIn)
        };
        //if (onHome) {
        //    onHome();
        //}
        //navigate('/dashboard');

    }, [logged]);

    return (

        <div className="home-container">
        {<NavBar />}
        <div className="home-banner-container">
            <div className="home-bannerImage-container">
                <img src={BannerBackground} alt="" />
            </div>
            <div className="welcome">
                <h1 className="info">Welcome</h1>
    
                {logged && (
                    <p className="info">
                        <b>You are logged into the system...</b> <br /> <br />
                    </p>
                )}
    
                {!logged && (
                    <div>
                        <p className="info">
                            <b>You are not logged into the system.</b> <br /> <br />
                        </p>
                    </div>
                )}
            </div>
            <div className="home-image-section">
                <img src={BannerImage} alt="" />
            </div>
        </div>
        <FooterBar />
        <LiveAgent></LiveAgent>
    </div>
    
    );
}

export default HomePage;
