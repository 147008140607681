import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Box } from "@mui/material";
import BannerBackground from "../assets/vector-8.png";
import BannerImage from "../assets/banner.png";
import NavBar from '../shared/navBar';
import './Contact.css';
import FooterBar from "../shared/footer";
import Toast from "../shared/toast";

function ContactUs() {
  const initialState = {
    fName: "",
    lName: "",
    email: "",
    subject: "",
    message: "",
  };

  const [fields, setFields] = useState(initialState);
  const [openToast, setOpenToast] = useState(false);
  const navigate = useNavigate();

  const resetForm = () => {
    setFields(initialState);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      /*const resp = await axios.post("https://myomar-contact-us.azurewebsites.net/api/httpTrigger1?", fields);
      console.log(resp);*/
      setOpenToast(true);
      resetForm();
    } catch (e) {
      console.log(e);
    }
  };

  const handleChange = (e, field) => {
    setFields(prev => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleCloseToast = () => {
    setOpenToast(false);
    navigate('/');
  };

  return (
    <div>
      <div className="home-container">
        {<NavBar />}
        <div className="home-banner-container">
          <div className="home-bannerImage-container">
            <img src={BannerBackground} alt="" />
          </div>
          <div className="home-text-section">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "90vh",
                width: "110vh"
              }}
            >
              <Box sx={{ maxWidth: 600, mx: "auto", p: 2 }}>
                <Typography variant="h3" align="center" mb={2} style={{ color: "#4c4c4c" }}>
                  Get In Touch With Us
                </Typography>
                <div style={{ marginTop: "1.5rem", marginBottom: "1.5rem", alignItems: "center", justifyContent: "center" }}>
                  <Typography variant="p" align="center" mb={4}>
                    Please fill out this form and we will get back to you as soon as possible!
                  </Typography>
                </div>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="First Name"
                    value={fields.fName}
                    onChange={e => handleChange(e, 'fName')}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Last Name"
                    value={fields.lName}
                    onChange={e => handleChange(e, 'lName')}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    value={fields.email}
                    onChange={e => handleChange(e, 'email')}
                    margin="normal"
                    required
                    type="email"
                  />
                  <TextField
                    fullWidth
                    label="Subject"
                    value={fields.subject}
                    onChange={e => handleChange(e, 'subject')}
                    margin="normal"
                    required
                  />
                  <TextField
                    fullWidth
                    label="Message"
                    value={fields.message}
                    onChange={e => handleChange(e, 'message')}
                    margin="normal"
                    required
                    multiline
                    rows={4}
                  />
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button variant='contained' type="submit" sx={{ mt: 2 }} style={{ align: "center", backgroundColor: "#1789C6", height: "60px", width: "150px", color: "white", borderBlockColor: "#1789C6", borderBlock: "0px", fontWeight: 'bold', margin: "1.5rem", borderRadius: "50px" }} >
                      Submit
                    </Button>
                  </div>
                </form>
              </Box>
            </Box>
          </div>
          <div className="home-image-section">
                        <img src={BannerImage} alt="" />
                    </div>
        </div>
      </div>
      <FooterBar />
      <Toast open={openToast} handleClose={handleCloseToast}
        message={'Thank you for contacting us!<br/>We have received your message and will get back to you as soon as possible.'} />

    </div>
  );
};

export default ContactUs;
