import { Button, TextField } from "@mui/material";
import React, { useState, useEffect, useRef } from 'react';
import { apiService } from '../services/apiService';
import './PartnerFind.css';
import PartnerDetail from './PartnerDetail';
import Autocomplete from '@mui/material/Autocomplete';
import LogoImage from "../assets/logo_partner.png";

const PartnerFind = () => {
  const [selectedCity, setSelectedCity] = useState('Select Location');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [partnerlst, setPartnerlst] = useState([]);
  const [filteredpartnerlst, setFilteredPartnerlst] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idpartner, setIdpartner] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const modalRef = useRef(null);

  const [windowSize, setWindowSize] = useState({
    width: 600,
    height: 250,
  });

  const [lstCities, setLstCities] = useState([]);

  useEffect(() => {
    if (window.innerWidth < 600)
    setWindowSize({
      width: 320,
      height: 230,
    });

    const fetchData = async () => {
      try {
        const cities = await apiService.citiesall();
        if (Array.isArray(cities.data)) {
          const names = cities.data.map(item => item.name);
          setLstCities(['All Locations', ...names]);
        }

        const response = await apiService.partnerall();
        if (!response) {
          throw new Error('Network response was not ok');
        }

        if (Array.isArray(response.data)) {
          const filteredPartners = response.data.filter(item => !item.name.startsWith('TEST'));

          setPartnerlst(filteredPartners);
        
          setFilteredPartnerlst(filteredPartners.filter(item => item.partnerAddress.city.name === '888'));
        
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    };

    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalOpen]);

  const handleClick = (id, event) => {
    setIdpartner(id);

    const cardRect = event.currentTarget.getBoundingClientRect();

    let top = cardRect.bottom;
    let left = cardRect.left;

    // Adjust if modal goes out of viewport
    if (top + windowSize.height + 100 > window.innerHeight) {
      top = window.innerHeight - windowSize.height - 100;
    }
    left = (window.innerWidth - windowSize.width) / 2;

    setPopupPosition({ top, left });
    setIsModalOpen(true);
  };

  const handleCityChange = (event, newValue) => {
    setSelectedCity(newValue);
  };

  const handleSearch = () => {

    if (selectedCity === 'All Locations') {
      setFilteredPartnerlst(partnerlst);
    }
    else {
      setFilteredPartnerlst(partnerlst.filter(item => item.partnerAddress.city.name === selectedCity));
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="content">
      <div className="controls">
        <Autocomplete
          options={lstCities}
          value={selectedCity}
          onChange={handleCityChange}
          style={{ flexGrow: 1, marginLeft: '5px', marginRight: '10px', maxWidth: '400px' }} // Adjust width and margin
          renderInput={(params) => (
            <TextField
              {...params}
              label="Find by Location"
              margin="normal"
              required
              fullWidth // Ensure full width of TextField within Autocomplete
            />
          )}
        />
        <Button variant="contained" color="primary" onClick={handleSearch} style={{marginRight: '5px'}}>
          Search
        </Button>
      </div>

      {filteredpartnerlst.map((item) => (
        <div key={item.id} className="card" onClick={(e) => handleClick(item.id, e)}>
          <div className="card-content">
            <div className="card-body">
                <img
                  src={item.logo ? `data:image/png;base64,${item.logo}` : LogoImage}
                  alt="Base64 Image"
                  className="logo-image"
                />
              <div className="vertical-line"></div>
              <div className="card-text">
                <b>{item.name}</b> - {item.partnerType.type}
              </div>
            </div>
          </div>

        </div>

      ))}

      {isModalOpen && (
        <div className="modalf">
          <div className="modalf-content" ref={modalRef} style={{ position: 'absolute', top: popupPosition.top, left: popupPosition.left }}>
            <div className="modalf-header">
              <Button className="close-button" onClick={() => setIsModalOpen(false)}>
                &times;
              </Button>
            </div>
            <PartnerDetail content data={idpartner} />
          </div>
        </div>
      )}
      <br />
      <br />
    </div>
  );
};

export default PartnerFind;
