 import { apiService } from '../services/apiService';
 import axiosInstance from "../axios/axiosInstance";
 import jwt_decode  from 'jwt-decode';
 
 const getEmail = async (email) => {
  return localStorage.getItem('email' ,email) ?? sessionStorage.getItem('email' ,email);
};

const getUserId = async () => {
  const token = getUserData();
  if(token){
      const payLoad = jwt_decode(token);
      const Param = payLoad?.Id;
      localStorage.setItem('id', Param);
      return localStorage.getItem('id');
    }
    else{
      return null;
    }
};

const getUserData = () => {
  const firstName = localStorage.getItem('firstName') ?? sessionStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName') ?? sessionStorage.getItem('lastName');
  const token = localStorage.getItem('token') ?? sessionStorage.getItem('token');
  const email = localStorage.getItem('email') ?? sessionStorage.getItem('email');
  const role = localStorage.getItem('role') ?? sessionStorage.getItem('role');
  const id = localStorage.getItem('id') ?? sessionStorage.getItem('id');
  const userpartnerid = sessionStorage.getItem('userpartnerid');
  const userpartner = sessionStorage.getItem('userpartner');
  const logo = sessionStorage.getItem('logo');
  const selectedPartner = sessionStorage.getItem('selectedPartner');
  if (token && email) {
      return { token, email, role, id, userpartnerid, userpartner, logo, selectedPartner, firstName, lastName};
  }
  return null;
};

 const login = async (userData, rememberMe) => {
    const logindata = await axiosInstance.post("User/login", userData);
    const roleuser = await getRoleByNumber(logindata.data.role);

    if (rememberMe) {
      localStorage.setItem('firstName' ,logindata.data.firstName);
      localStorage.setItem('lastName' ,logindata.data.lastName);
      localStorage.setItem('token', logindata.data.token);
      localStorage.setItem('email' ,logindata.data.email);
      localStorage.setItem('role', roleuser);
      localStorage.setItem('id' ,logindata.data.id);
    } else {
      sessionStorage.setItem('firstName' ,logindata.data.firstName);
      sessionStorage.setItem('lastName' ,logindata.data.lastName);
      sessionStorage.setItem('token', logindata.data.token);
      sessionStorage.setItem('email' ,logindata.data.email);
      sessionStorage.setItem('role', roleuser);
      sessionStorage.setItem('id' ,logindata.data.id);
    }

    const partnerData = await apiService.partnerByUserId();

      sessionStorage.setItem('userpartnerid', partnerData.data === '' ? '' : partnerData.data.id);
      sessionStorage.setItem('userpartner', partnerData.data === '' ? '' : partnerData.data.name);
      sessionStorage.setItem('logo', partnerData.data === '' ? '' : partnerData.data.logo);
  

    logindata.role = roleuser;
    return logindata;
 };

  //trata o token que foi passado na querystring de reset password
  const getDatabyToken =  async (token) => {
   if(token) {
       const payLoad = jwt_decode(token);
       const Param = payLoad?.Id;
       localStorage.setItem('id', Param);
       const userData = { Param };
       return await axiosInstance.post("User/byid", userData );
}};


 const isLoggedIn = async () => {
    const token = localStorage.getItem('token') ?? sessionStorage.getItem('token');
    if(token){
        const payLoad = jwt_decode(token);
        const isLogin = Date.now() < new Date(payLoad.exp * 1000);
        return isLogin;

    }
 };

 const logOut = async ()=> {
    localStorage.clear();
    sessionStorage.clear();
 };

const eRoles = {
  Admin: 1,
  Partner: 2,
  Lab_Manager: 3,
  Client: 4,
  Practitioner: 5,
  AuthorizedUser: 6, //usuário nomeado pelo partner
  Undefined: 9
};

const getRoleByNumber = async(number) => {
  for (const key in eRoles) {
    if (eRoles[key] === number) {
      return key;
    }
  }
  return null; 
};

const getNumberByRole = async(strrole) => {
  const numrole =  eRoles[strrole];
  return numrole; 
};

 export  const authService = { logOut, getUserData, getEmail, getUserId, login, getDatabyToken, getRoleByNumber,getNumberByRole, isLoggedIn};