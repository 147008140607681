import "./navBar.css";
import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from 'react-router-dom';
import { Box, FormControl, Select, MenuItem } from "@mui/material";
import { HiOutlineBars3 } from "react-icons/hi2";
import Logo from "../assets/Logo.png";
import { authService } from "../services/authService";
import urlHomeBase from '../services/urlBase';
import { apiService } from '../services/apiService';
import LogoImage from "../assets/logo_partner.png";

const NavBar = () => {
  const [useremail, setEmail] = useState();
  const [userRole, setUserRole] = useState();
  const [userHome, setUserHome] = useState('/home');
  const [logo, setLogo] = useState();
  const [partners, setPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openMenu, setOpenMenu] = useState(false); // Estado para controlar o menu lateral

  const navigate = useNavigate();

  const logout = () => {
    authService.logOut();
    navigate('/Home');
    window.location.reload();
  };

  useEffect(() => {
    fetchData();

    async function fetchData() {
      const isLoggedIn = await authService.isLoggedIn();
      if (isLoggedIn) {
        const userdata = await authService.getUserData();
        const baseUrl = urlHomeBase[userdata.role];
        setUserRole(userdata.role);
        setUserHome(baseUrl);
        setEmail(userdata.email);
        setLogo(userdata.logo);

        const partnersResponse = await apiService.partnerByUserId();
        if (partnersResponse.data[0]) {
          setPartners(partnersResponse.data);
          const selectedPartner = sessionStorage.getItem('selectedPartner');
          if (!selectedPartner) {
            const partnerstart = partnersResponse.data[0];
            SelectPartner(partnerstart.id);
          }
        }
      }
      setIsLoading(false);
    }
  }, []);

  const handleSelectPartner = async (e) => {
    SelectPartner(String(e));
  };

  const SelectPartner = async (id) => {
    const partnerData = await apiService.partnerbyid(id);
    sessionStorage.setItem('userpartnerid', partnerData.data === '' ? '' : partnerData.data.id);
    sessionStorage.setItem('userpartner', partnerData.data === '' ? '' : partnerData.data.name);
    sessionStorage.setItem('logo', partnerData.data === '' ? LogoImage : partnerData.data.logo);
    sessionStorage.setItem('selectedPartner', id);
    window.location.reload();
  };

  return (
    <nav style={{ padding: "0px" }}>
      <div className="nav-logo-container">
        <img style={{ width: "150px", height: "82px" }} src={Logo} alt="" onClick={() => navigate('/home')} />
      </div>

      <div className="navbar-links-container">

        <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>

          {!userRole && (
            <>
              <NavLink to="/bepartner" activeclassname="active-link" style={{ textDecoration: "none", marginLeft: "20px" }}>
                Be Our Partner
              </NavLink>
              <NavLink to="/login" activeclassname="active-link" style={{ textDecoration: "none", marginLeft: "20px" }}>
                Log In
              </NavLink>
            </>
          )}
          {userRole && (
            <div style={{ fontSize: '12px', textAlign: 'center', marginLeft: '20px' }}>
              <b>{userRole}:</b> {useremail}
              <span
                style={{
                  cursor: 'pointer',
                  borderBottom: "none",
                  fontWeight: "600",
                  fontSize: "1.1rem",
                  textDecoration: "none",
                  marginLeft: "20px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                Logout
              </span>
            </div>
          )}
        </div>

        {((userRole === "Partner" || userRole === "AuthorizedUser") && partners.length > 0) && (
  <div className="UserDiv">
    {isLoading ? (
      <p>Loading partners...</p>
    ) : (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormControl size="medium" variant="standard">
          <Select
            sx={{ fontSize: 14, fontWeight: 'bold' }}
            value={sessionStorage.getItem('selectedPartner') || ''}
            onChange={(e) => handleSelectPartner(e.target.value)}
          >
            {partners.length > 0 ? (
              partners.map((partner) => (
                <MenuItem key={partner.id} value={partner.id} sx={{ fontSize: 14 }}>
                  <b>{partner.name}</b>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No partners available</MenuItem>
            )}
          </Select>
        </FormControl>
        <img 
          src={logo ? `data:image/png;base64,${logo}` : LogoImage} 
          className="logo-image-nav" 
          alt="Partner Logo" 
        />
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
