import React, { useEffect } from 'react';

const LiveAgent = () => {
    useEffect(() => {
        // Create the script element
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.defer = true;
        script.src = 'https://myomarmolecular.ladesk.com/scripts/track.js';
        script.id = 'la_x2s6df8d';

        // Define the callback after the script is loaded
        script.onload = script.onreadystatechange = function () {
            const rs = this.readyState;
            if (rs && rs !== 'complete' && rs !== 'loaded') return;

            // Call LiveAgent.createButton once the script is loaded
            if (window.LiveAgent) {
                window.LiveAgent.createButton('dwmu6j97', script);
            }
        };

        // Append the script to the document
        const targetScript = document.scripts[document.scripts.length - 1];
        targetScript.parentElement.insertBefore(script, targetScript.nextSibling);

        // Cleanup the script when the component unmounts
        return () => {
            if (script.parentElement) {
                script.parentElement.removeChild(script);
            }
        };
    }, []);

    return (null); // No need to render anything
};

export default LiveAgent;
