// GaugeChartDialog.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import HalfDoughnutChart from '../components/HalfDoughnutChart';

const GaugeChartDialog = ({ open, handleClose, data, title }) => {
  const fields = [
    { name: "Metabolism", value: data?.metabolism },
    { name: "Behaviour", value: data?.behaviour },
    { name: "Muscle", value: data?.muscle },
    { name: "Performance", value: data?.performance },
    { name: "Nutrition", value: data?.nutrition },
    { name: "Efficiency", value: data?.efficiency },
    { name: "Recovery", value: data?.recovery },
  ];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
          {fields.map((field, index) => (
            <div key={index} style={{ width: '30%', marginBottom: '20px' }}>
              <HalfDoughnutChart
                label={field.name}
                percentage={parseFloat(field.value)}
              />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GaugeChartDialog;
